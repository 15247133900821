import React, { FunctionComponent, RefObject, useEffect, useRef, useState } from 'react';
import api from './api';
import { getFaviconUrl } from './utilities';
import { GeneratedItem } from '../../types';
import { FixedCost, FixedDue, Type, VariableCost, VariableDue } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';


export const HomeRoute: FunctionComponent<{ entityId: string }> = ({ entityId }) => {
    return <div className='container'>
        <div className='row m-3'>
            <div className='col'>
                <Home entityId={entityId} />
            </div>
        </div>
    </div>
}

const inclusiveSplit = <T,>(array: Array<T>, fn: (item: T) => boolean): Array<Array<T>> => {
    const result: Array<Array<T>> = [];
    let currentArray: Array<T> = [];

    for (const item of array) {
        if (fn(item)) {
            if (currentArray.length > 0) {
                result.push(currentArray);
            }
            currentArray = [item];
        } else {
            currentArray.push(item);
        }
    }

    if (currentArray.length > 0) {
        result.push(currentArray);
    }

    return result;
}
const PriceEditor: FunctionComponent<{ cost: number, onCost: (cost: number) => void }> = ({ cost, onCost }) => {
    const [nextCost, setNextCost] = useState(cost)
    const [costString, setCostString] = useState(cost.toFixed(2));
    const clean = (costString: string): string => {
        return costString.replace(/[^0-9.]/g, '');
    }
    useEffect(() => {
        setNextCost(parseFloat(costString));
    }, [costString]);
    return <form onSubmit={(e) => {
        e.preventDefault();
        onCost(nextCost);
    }}>
        <div className='input-group input-group-sm'>
            <input type='number' className='form-control' value={costString} step='any' onChange={e => setCostString(clean(e.currentTarget.value))} />
            <button className="btn btn-outline-secondary" type="submit" disabled={isNaN(nextCost)}>
                <FontAwesomeIcon icon={faCheck} />
            </button>
        </div >
    </form>
}
enum DueType {
    Due,
    PayOn,
    Late
}
const DueEditor: FunctionComponent<{
    dueType: DueType,
    onDueType: (dueType: DueType) => void,
    due: number,
    onDue: (due: number) => void
}> = ({ dueType, onDueType, due, onDue }) => {
    const getDateFormatted = (date: number): string => {
        if (!date) return '';
        const convDate = new Date(date);
        return [convDate.getFullYear(), convDate.getMonth() + 1, convDate.getDate()].map(part => part < 10 ? '0' + part : part).join('-');
    }

    const [nextDue, setNextDue] = useState<number | undefined>(due);
    const [dueString, setDueString] = useState<string>(getDateFormatted(due));
    const [showDueDropdown, setShowDueDropdown] = useState(false);

    useEffect(() => {
        const dateNumber = getDateNumber(dueString);
        if (dateNumber) setNextDue(dateNumber);
    }, [dueString]);



    const getDateNumber = (nextValue?: string): number | undefined => {
        if (!nextValue) return;
        const [year, month, day] = nextValue.split('-').map(part => parseInt(part));
        return new Date(year, month - 1, day).getTime();
    }
    const getDueTypeName = (type: DueType): string => {
        if (type === DueType.Due) return 'Due';
        if (type === DueType.PayOn) return 'Pay on';
        if (type === DueType.Late) return 'Late';
        return '';
    }
    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            if (nextDue) onDue(nextDue);
        }}>
            <div className='input-group input-group-sm dropdown-small'>
                <button
                    className="btn btn-sm btn-outline-secondary dropdown-toggle"
                    type="button"
                    onClick={() => setShowDueDropdown(!showDueDropdown)}
                >{getDueTypeName(dueType)}</button>
                <div className={"dropdown-menu " + (showDueDropdown ? 'show' : '')}>
                    {[DueType.Due, DueType.PayOn, DueType.Late].map(type =>
                        <a className="dropdown-item cursor-pointer" onClick={() => {
                            setShowDueDropdown(false);
                            onDueType(type);
                        }}>{getDueTypeName(type)}</a>)}
                </div>
                <input
                    type='date'
                    className='form-control'
                    value={dueString}
                    onChange={e => setDueString(e.currentTarget.value)} />
                <button className="btn btn-outline-secondary" type="submit" disabled={!nextDue}>
                    <FontAwesomeIcon icon={faCheck} />
                </button>
            </div>
        </form >
    );
}

const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void) => {
    useEffect(() => {
        const handleEventOutside = (event: MouseEvent | TouchEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };
        document.addEventListener('mousedown', handleEventOutside);
        document.addEventListener('touchstart', handleEventOutside);

        return () => {
            document.removeEventListener('mousedown', handleEventOutside);
            document.removeEventListener('touchstart', handleEventOutside);
        };
    }, [ref, callback]);
};
const enum FitType {
    Exact,
    PossiblyDelayable,
    DefinitelyDelayable
}
export const DueEditorType: FunctionComponent<{
}> = ({ }) => {
    return <div>

    </div>
}
export const Item: FunctionComponent<{
    item: GeneratedItem<number>,
    type: FitType,
    togglePaid: (paid: boolean) => void,
    costUpdate: (cost: number) => void,
    dueUpdate: (due: number) => void,
    payOnUpdate: (due: number) => void,
    onMemoUpdate: (memo: string) => Promise<void>
}> = ({ item, togglePaid, costUpdate, dueUpdate, payOnUpdate, onMemoUpdate, type }) => {
    const [showPriceEditor, setShowPriceEditor] = useState(false);
    const [showDueEditor, setShowDueEditor] = useState(false);
    const getDueType = (): DueType => {
        if (item.due.type === Type.Variable) return DueType.Due

        return DueType.PayOn;
    }
    const [dueType, setDueType] = useState(getDueType());
    const [showMemoEditor, setShowMemoEditor] = useState(false);
    const [nextMemoText, setNextMemoText] = useState(item.memo || '');
    const containerRef = useRef<HTMLDivElement>(null);

    const faviconUrl = getFaviconUrl(item.url);

    const getDue = (due: FixedDue<number> | VariableDue<number>) => {
        if (due.type === Type.Fixed) {
            return <span>{new Date(due.date).toLocaleDateString()}</span>
        } else {
            return <span>{new Date(due.range[0]).toLocaleDateString() + ' - ' + new Date(due.range[1]).toLocaleDateString()}</span>
        }
    }
    const getPriceBlock = (cost: FixedCost | VariableCost) => {
        if (cost.type === Type.Fixed) {
            return <>
                <span>${cost.cost}</span>
                {cost.lateCost && <small className='text-muted' title='Cost if late'>${getAdjustedPrice(cost.lateCost)}</small>}
            </>
        } else {
            return <div>
                <div><span className='text-nowrap'>${cost.range[0] + ' - $' + cost.range[1]}</span></div>
                {cost.lateRange && <div>
                    <small className='text-nowrap text-muted' title='Cost if late'>
                        ${getAdjustedPrice(cost.lateRange[0])} - ${getAdjustedPrice(cost.lateRange[1])}
                    </small>
                </div>}
            </div>
        }
    }


    useClickOutside(containerRef, () => {
        setShowPriceEditor(false);
        setShowDueEditor(false);
        if (showMemoEditor) {
            onMemoUpdate(nextMemoText).then(() => { })
                .catch(() => { })
                .then(() => {
                    setShowMemoEditor(false);
                })
        }
    });

    const getFitColor = (type: FitType): string => {
        if (type === FitType.Exact)
            return '';
        if (type === FitType.PossiblyDelayable) {
            return 'text-warning text-muted';
        }
        if (type === FitType.DefinitelyDelayable) {
            return 'text-warning';
        }

        return '';
    }

    const getLateOn = (): string => {
        if (item.due.type === Type.Variable) {
            return new Date(item.due.lateOnRange[0]).toLocaleDateString() + ' to ' + new Date(item.due.lateOnRange[1]).toLocaleDateString();
        } else {
            return new Date(item.due.lateOn).toLocaleDateString();
        }
    }

    return <div key={item.id} className='list-group-item' ref={containerRef}
    >
        <div className='row'>
            <div className='col-md-3 d-flex align-items-center' onClick={() => {
                if (showDueEditor) return;
                setShowDueEditor(true)
            }}>
                <input type='checkbox' className='me-1' checked={item.paid}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onChange={e => {
                        togglePaid(e.currentTarget.checked)
                    }} />

                {!showDueEditor ?
                    <span className={getFitColor(type)} title={'Late on ' + getLateOn()}>
                        {getDue(item.due)}
                    </span>
                    : <DueEditor
                        onDueType={dueType => setDueType(dueType)}
                        dueType={dueType}
                        due={item.due.type === Type.Fixed ? item.due.date : item.due.range[0]}
                        onDue={due => {
                            setShowDueEditor(false);
                            if (dueType === DueType.Due) {
                                dueUpdate(due);
                            }
                            if (dueType === DueType.PayOn) {
                                payOnUpdate(due);
                            }
                        }} />
                }
            </div>
            <div className={'col-md-4 d-flex align-items-center ' + (item.income ? 'text-success' : '')}>
                {item.url && <a href={item.url} target='_blank'>
                    {faviconUrl ? <img src={faviconUrl} className='me-1' width={16} height={16} /> : <></>}
                </a>}{item.name}
            </div>
            <div className='col-md-2 d-flex align-items-center' onClick={() => !showPriceEditor && setShowPriceEditor(true)}>
                {!showPriceEditor
                    ? getPriceBlock(item.cost)
                    : <PriceEditor
                        cost={item.cost.type === Type.Fixed ? item.cost.cost : item.cost.range[0]}
                        onCost={cost => {
                            setShowPriceEditor(false);
                            costUpdate(cost);
                        }} />
                }
            </div>
            <div className='col-md-3 d-flex align-items-center'>
                {showMemoEditor ?
                    <textarea className='form-control' value={nextMemoText} onChange={e => setNextMemoText(e.currentTarget.value)} spellCheck={false} />
                    : item.memo
                        ? <span onClick={() => setShowMemoEditor(true)}>{item.memo}</span>
                        : <small className='text-muted' onClick={() => setShowMemoEditor(true)}>Memo..</small>}
            </div>
        </div>
    </div>
}
interface PotentialIncomeGroup {
    isIncomeGroup: boolean;
    duration?: number
}
interface DefiniteIncomeGroup {
    isIncomeGroup: true;
    duration: number;
}
const getAdjustedPrice = (price: number) => {
    const fixed = price.toFixed(2);
    const number = parseFloat(fixed);
    return number % 1 !== 0 ? fixed : number;
}
export const ItemGroup: FunctionComponent<{
    entityId: string,
    items: Array<GeneratedItem<number>>,
    refreshGenerated: () => void
} & (PotentialIncomeGroup | DefiniteIncomeGroup)> = ({ entityId, items, isIncomeGroup, duration, refreshGenerated }) => {
    const today = new Date();

    const fitsInGroup = (date: Date) => {
        if (!duration) return false;

        const start = new Date(items[0].due.type === Type.Fixed ? items[0].due.date : items[0].due.range[0]);
        const end = new Date(start.getTime() + duration);

        if (date >= start && date <= end) return true;
        return false;
    }
    const highlight = fitsInGroup(today);
    const incomePrice = items.filter(item => item.income).reduce((acc, item) => {
        if (item.cost.type === Type.Fixed) {
            return [acc[0] + item.cost.cost, acc[1] + item.cost.cost];
        } else {
            return [acc[0] + item.cost.range[0], acc[1] + item.cost.range[1]];
        }
    }, [0, 0])
    const expensesPrice = items.filter(item => !item.income).reduce((acc, item) => {
        if (item.cost.type === Type.Fixed) {
            return [acc[0] + item.cost.cost, acc[1] + item.cost.cost];
        } else {
            return [acc[0] + item.cost.range[0], acc[1] + item.cost.range[1]];

        }
    }, [0, 0])



    const left = [incomePrice[0] - expensesPrice[0], incomePrice[1] - expensesPrice[1]];

    const getType = (item: GeneratedItem<number>): FitType => {
        if (!isIncomeGroup) return FitType.Exact;
        if (item.due.type === Type.Fixed) {
            if (!fitsInGroup(new Date(item.due.lateOn))) {
                return FitType.DefinitelyDelayable
            } else {
                return FitType.Exact;
            }
        } else {
            if (!fitsInGroup(new Date(item.due.lateOnRange[0]))) {
                return FitType.DefinitelyDelayable
            } else if (!fitsInGroup(new Date(item.due.lateOnRange[1]))) {
                return FitType.PossiblyDelayable
            } else {
                return FitType.Exact
            }
        }
    }

    return <div className={'list-group my-3 ' + (highlight ? 'border border-warning' : '')} >
        {items.map(item => <Item
            type={getType(item)}
            item={item}
            key={item.id}
            costUpdate={cost => {
                api.setCost(entityId, item.id, cost)
                    .then(() => refreshGenerated())
            }}
            togglePaid={paid => {
                api.setPaid(entityId, item.id, paid)
                    .then(() => refreshGenerated())
            }}
            dueUpdate={due => {
                api.setDue(entityId, item.policyId, item.id, due)
                    .then(() => refreshGenerated())
            }}
            payOnUpdate={due => {
                api.setPayOn(entityId, item.id, due)
                    .then(() => refreshGenerated())
            }}
            onMemoUpdate={memo => api.setMemo(entityId, item.id, memo)
                .then(() => refreshGenerated())
            }
        />)}
        {isIncomeGroup && <div className='list-group-item'>
            <div className='row'>
                <div className='col d-flex align-items-center'>
                    <strong>Summary</strong>
                </div>
                <div className='col'>
                    <div className='d-flex justify-content-center'>
                        <strong>Expenses</strong>
                    </div>
                    <div className='d-flex justify-content-center'>
                        {expensesPrice[0] === expensesPrice[1]
                            ? '$' + getAdjustedPrice(expensesPrice[0])
                            : '$' + getAdjustedPrice(expensesPrice[0]) + ' - $' + getAdjustedPrice(expensesPrice[1])}
                    </div>
                </div>
                <div className='col'>
                    <div className='d-flex justify-content-center'>
                        <strong>Left</strong>
                    </div>
                    <div className='d-flex justify-content-center'>
                        {left[0] === left[1] ? '$' + getAdjustedPrice(left[0]) : '$' + getAdjustedPrice(left[0]) + ' - $' + getAdjustedPrice(left[1])}
                    </div>
                </div>
            </div>
        </div>}
    </div>
}
export const Home: FunctionComponent<{ entityId: string }> = ({ entityId }) => {
    const [startDate, setStartDate] = useState(undefined as Date | undefined);
    const [endDate, setEndDate] = useState(undefined as Date | undefined);
    const [generated, setGenerated] = useState([] as Array<GeneratedItem<number>>);
    const [incomes, setIncomes] = useState([] as Array<{ id: number, duration: number }>);
    const [error, setError] = useState('');

    const formatDate = (date: Date) => {
        const d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [year,
            (month.length < 2) ? '0' + month : month,
            (day.length < 2) ? '0' + day : day].join('-');
    }

    const refreshGenerated = () => {
        if (!startDate || !endDate) return;

        api.generateEntity(entityId, startDate, endDate)
            .then((fullEntity) => {
                setGenerated(fullEntity.generated);
                setIncomes(fullEntity.defaultPolicies);
                setError('');
            })
            .catch(err => {
                setError(err);
            })
    }

    useEffect(() => {
        if (!entityId) return;

        api.generateDefaultEntity(entityId)
            .then(entity => {
                setGenerated(entity.generated);
                setIncomes(entity.defaultPolicies);
                setStartDate(new Date(entity.from));
                setEndDate(new Date(entity.to));
            })
            .catch(err => {
                setError(err);
            })
    }, [entityId]);

    useEffect(() => {
        const lastTimeout = setTimeout(() => refreshGenerated(), 200);
        return () => {
            clearTimeout(lastTimeout);
        }
    }, [startDate, endDate, entityId]);

    return <div>
        <div className={'row ' + (error ? '' : 'd-none')}>
            <div className='alert alert-danger'>
                {error}
            </div>
        </div>
        <div className='row'>
            <div className='col-sm-4 col-md-3 offset-md-2'>
                <input
                    type='date'
                    className='form-control'
                    value={startDate ? formatDate(startDate) : ''}
                    style={{
                        'textAlign': 'right'
                    }}
                    onChange={e => setStartDate(new Date(e.currentTarget.value))} />

            </div>
            <div className='col-sm-4 col-md-3 offset-md-2'>
                <input
                    type='date'
                    className='form-control'
                    value={endDate ? formatDate(endDate) : ''}
                    onChange={e => setEndDate(new Date(e.currentTarget.value))} />
            </div>
        </div>
        <div className='row mt-2'>
            <div className='col-md-10 offset-md-1'>
                {inclusiveSplit(generated, item => incomes.findIndex(income => income.id === item.policyId) !== -1)
                    .map((group, i, groups) => <ItemGroup
                        key={group[0]?.id || i}
                        entityId={entityId}
                        items={group}
                        isIncomeGroup={groups.length > 1}
                        duration={incomes.find(income => income.id === group[0]?.policyId)?.duration}
                        refreshGenerated={() => refreshGenerated()}
                    ></ItemGroup>)}
            </div>
        </div>
    </div>
}

const LandingDivOne = () => {
    return <div style={{ backgroundColor: 'rgb(3, 0, 22)' }} className='d-flex justify-content-center'>
        <img src='/rex.webp' />
    </div>
}
export const LandingRoute: FunctionComponent<{}> = ({ }) => {
    return <div>
        <LandingDivOne />
        <div>
            This is a landing page
        </div>
    </div>
}